@font-face {
  font-family: "Filled";
  src: local("Starjedi"),
       url("./fonts/Starjedi.ttf") format("truetype");
}

@font-face {
  font-family: "Hollow";
  src: local("Starjhol"),
       url("./fonts/Starjhol.ttf") format("truetype");
}

@font-face {
  font-family: "Gothic";
  src: local("FranklinGothic"),
       url("./fonts/FranklinGothic.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
