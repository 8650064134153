* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden;
  overflow-y:visible; */

  font-family: "Gothic";
  /* background-color: black; */
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

html {
  scroll-behavior: smooth;
}

.App {
  /* text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; */
  /* background-color: rgb(0, 0, 0);
  --color: black; */
} 

/* .heading {
  color: #FFE81F;
  font-family: "Hollow";
  font-size: 50px;
}

.body {
  font-family: "Gothic";
  color: #d6c31a;
}  */

p {
  color: whitesmoke;
}
