#to-top-button {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    background-color: white;
    border-radius: 45px;
    min-width: 45px;
    min-height: 45px;
}

.sort-options-container {
    width: 100%;
    padding-top: 2%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.sort-options-1 {
    width: 45%;
    padding-right: 0.5%;
}

.sort-options-2 {
    width: 45%;
    padding-left: 0.5%;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 550px;
}

.loadingModal {
    display: block;
    background-image: linear-gradient(to right, rgb(70, 70, 70) , rgb(0, 0, 0));
    margin: 1%;
    border-radius: 20px;
    height: 20%;
}