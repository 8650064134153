p {
    font-size: 1rem;
}

.heading {
    font-size: 2rem;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 20%;   
}

@keyframes slide-to-left {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-75%);
    }
}

@keyframes slide-to-right {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(-25%);
    }
}

.hidden {
    opacity: 0;
    filter: blur(3px);
    transition: all 0.8s;
}

.slide-left {
    opacity: 1;
    filter: blur(0px);
    animation: slide-to-left 1s ease-out;
}

.slide-right {
    opacity: 1;
    filter: blur(0px);
    animation: slide-to-right 1s ease-out;
}

.heros-container {
    position: relative;
    overflow: hidden;
    width: 100%; 
    height: auto;
    
}

.hero-container {
    position: relative;
}

.welcome-hero {
    position: relative;
    display: block;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.welcome-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.films-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%; 
    opacity: 60%;
}

.films-text {
    position: absolute;
    top: 20%;
    left: 60%;
    transform: translateX(-75%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.people-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.people-text {
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translateX(-25%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.species-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.species-text {
    position: absolute;
    top: 20%;
    left: 60%;
    transform: translateX(-75%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.planets-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.planets-text {
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translateX(-25%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.starships-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.starships-text {
    position: absolute;
    top: 20%;
    left: 60%;
    transform: translateX(-75%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

.vehicles-hero {
    position: relative;
    display: block;
    bottom: 100%;
    left: 0;
    height: auto;
    width: 100%;
    opacity: 60%;
}

.vehicles-text {
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translateX(-25%);
    width: 100%;
    color: rgb(255, 255, 255);
    text-align: center;
}

/* .home-button {
    width: 15%;
    height: auto;
}

.home-button p {
    font-size: auto;
    
} */

@media (width <= 830px) {

    p {
        font-size: 0.8rem;
    }

    .heading {
        font-size: 1.7rem;
    }

}

@media (width <= 600px) {

    p {
        font-size: 0.65rem;
    }

    .heading {
        font-size: 1.4rem;
    }

}

@media (width <= 465px) {

    p {
        font-size: 0.52rem;
    }

    .heading {
        font-size: 1.2rem;
    }

}