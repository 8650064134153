#footer {
    background-color: rgb(0, 0, 0);
}

#footer > #footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text {
    padding-top: 12px;
    font-size: 0.8rem;
    color: whitesmoke;
}

#footer > #footer-header {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-bottom: -30px;
}

.footer-icons {
    width: 50px;
    height: auto;
}

#footer > #footer-header .footer-text {
    font-size: 1.2rem;
}
