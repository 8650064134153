.loading {
    color: white;
}

.film-cards-grid {
    padding-top: 5%;
    padding-bottom: 3.5%;
    overflow: hidden;
}

.sort-options {
    width: 90%;
}
